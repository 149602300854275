// listens for changes on the torchbox-mode cookie and updates the base class

document.addEventListener('DOMContentLoaded', () => {
    const allowedModes = JSON.parse(
        document.querySelector('#allowed_modes').textContent,
    );
    const html = document.querySelector('html');

    cookieStore.addEventListener('change', (event) => {
        if (event.changed[0].name === 'torchbox-mode') {
            let newMode = event.changed[0].value;
            html.classList.forEach((className) => {
                if (className.startsWith('mode-')) {
                    html.classList.remove(className);
                }
            });
            if (allowedModes.includes(newMode)) {
                html.classList.add(`mode-${newMode}`);
            }
        }
    });
});
